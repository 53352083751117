// ==============================
// Custom style
// 自定义样式
// ==============================

@font-face {
    font-family: MFZhiYan;
    src: url('/fonts/MFZhiYan.ttf');
}

.desktop .header-title {
    line-height: 1em;
}

.header-title a{
    font-family: "MFZhiyan";
    letter-spacing: -5px;
    font-size: 1.4em;
}

.header-title .chu {
    letter-spacing: -8px;
}

.header-title .you {
    letter-spacing: -10px;
}

.gongan-logo {
    height: 16px;
    vertical-align:middle;
}
